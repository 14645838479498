import React, { useState, useEffect } from "react";
import CategoryList from "../components/CategoryList";
import BannerProduct from "../components/BannerProduct";
import VerticalCardProduct from "../components/VerticalCardProduct";
import fetchCategoryWiseProduct from "../helpers/fetchCategoryWiseProduct";
import { useSelector } from "react-redux";

const Home = () => {
  const [allProducts, setAllProducts] = useState({});

  const user = useSelector(state => state?.user?.user)

  useEffect(() => {
    const fetchData = async () => {
      const categories = [
        "new arrivals",
        "top ranking",
        "men's clothing",
        "women's clothing",
        "kids wear Toys",
        "shoes accessories",
        "luggage bags",
        "beauty products",
        "electronic components",
        "home appliances",
        "Glossary items",
        "Books Stationery",
      ];

      try {
        const response = await fetchCategoryWiseProduct(categories);
        if (response && response.length > 0) {
          const allData = response;

          // Group products by category
          const groupedProducts = categories.reduce((acc, category) => {
            acc[category] = allData.filter(
              (product) => product.category === category
            );
            return acc;
          }, {});

          setAllProducts(groupedProducts);
        } else {
          console.error("Invalid response structure:", response);
        }
      } catch (error) {
        console.error("Error fetching products:", error);
      }
    };

    fetchData();
  }, [user]);
  
  return (
    <div>
      <CategoryList key="CategoryList" />
      <BannerProduct key="BannerProduct" />
      <VerticalCardProduct
        key="VerticalCardProduct1"
        category={"new arrivals"}
        heading={"New Arrivals"}
        products={allProducts["new arrivals"] || []}
      />
      <VerticalCardProduct
        key="VerticalCardProduct2"
        category={"top ranking"}
        heading={"Top Ranking"}
        products={allProducts["top ranking"] || []}
      />
      <VerticalCardProduct
        key="VerticalCardProduct3"
        category={"men's clothing"}
        heading={"Men's Clothing"}
        products={allProducts["men's clothing"] || []}
      />
      <VerticalCardProduct
        key="VerticalCardProduct4"
        category={"women's clothing"}
        heading={"Women's Clothing"}
        products={allProducts["women's clothing"] || []}
      />
      <VerticalCardProduct
        key="VerticalCardProduct5"
        category={"kids wear Toys"}
        heading={"Kids Wear & Toys"}
        products={allProducts["kids wear Toys"] || []}
      />
      <VerticalCardProduct
        key="VerticalCardProduct6"
        category={"shoes accessories"}
        heading={"Shoes & Sports Accessories"}
        products={allProducts["shoes accessories"] || []}
      />
      <VerticalCardProduct
        key="VerticalCardProduct7"
        category={"luggage bags"}
        heading={"Luggage & Bags"}
        products={allProducts["luggage bags"] || []}
      />
      <VerticalCardProduct
        key="VerticalCardProduct8"
        category={"beauty products"}
        heading={"Beauty Products"}
        products={allProducts["beauty products"] || []}
      />
      <VerticalCardProduct
        key="VerticalCardProduct9"
        category={"electronic components"}
        heading={"Electronic Components"}
        products={allProducts["electronic components"] || []}
      />
      <VerticalCardProduct
        key="VerticalCardProduct10"
        category={"home appliances"}
        heading={"Home Appliances"}
        products={allProducts["home appliances"] || []}
      />
      <VerticalCardProduct
        key="VerticalCardProduct11"
        category={"Glossary items"}
        heading={"Glossary Items"}
        products={allProducts["Glossary items"] || []}
      />
      <VerticalCardProduct
        key="VerticalCardProduct12"
        category={"Books Stationery"}
        heading={"Books & Stationery"}
        products={allProducts["Books Stationery"] || []}
      />
    </div>
  );
};

export default Home;
